import { FC, useEffect } from 'react';
import { Router } from './routes';
import { usePrevious } from './hooks';
import { useQuery } from '@apollo/client';
import { currentUserQuery } from '../core-types';
import { useSystemConfig } from './system-config-context';
import { CURRENT_USER_QUERY } from './core/useCurrentUser';

declare global {
  interface Window {
    Intercom?: {
      (command: string, ...args: any[]): void;
      booted: boolean;
    };
  }
}

Router.events.on('routeChangeComplete', () => {
  if (window.Intercom) {
    window.Intercom('update');
  }
});

export enum InitialIntercomScreen {
  HOME = 'home',
  NEW_MESSAGE = 'new',
  MESSAGE_LIST = 'messages',
  HELP = 'help'
}

export interface CustomConfig {
  user_id?: string;
  user_hash?: string;
  ruter_user_id?: string;
  trip_id?: string;
  initial_screen?: InitialIntercomScreen;
}

export function useIntercom(
  hideLauncher: boolean | undefined,
  customConfig?: CustomConfig
) {
  const systemConfig = useSystemConfig();
  const { disableIntercom } = systemConfig.featureFlags;
  const { data } = useQuery<currentUserQuery>(CURRENT_USER_QUERY);

  let intercomSettings: {
    hide_default_launcher: boolean;
    app_id?: string;
    user_id?: string;
    user_hash?: string;
    ruter_user_id?: string;
    trip_id?: string;
  } = { hide_default_launcher: !!hideLauncher };

  if (systemConfig.intercomId) {
    intercomSettings.app_id = systemConfig.intercomId;
  }

  if (data?.intercomSettings) {
    intercomSettings = { ...intercomSettings, ...data.intercomSettings };
  }

  if (customConfig) {
    intercomSettings = { ...intercomSettings, ...customConfig };
  }

  // Wait for window.Intercom to boot (max 10 seconds)
  useEffect(() => {
    if (disableIntercom) {
      return;
    }
    const interval = setInterval(() => {
      if (window.Intercom && window.Intercom.booted) {
        if (intercomSettings) {
          window.Intercom('boot', intercomSettings);
        }
        clearInterval(interval);
      }
    }, 100);
    return () => {
      clearInterval(interval);
    };
  }, [intercomSettings, disableIntercom]);

  const prevIntercomSettings = usePrevious<
    currentUserQuery['intercomSettings']
  >(intercomSettings);

  useEffect(() => {
    if (disableIntercom || !window.Intercom) {
      return;
    }
    // if there have been a user_id, and its not there anymore, assume the user logged out
    if (
      prevIntercomSettings &&
      prevIntercomSettings.user_id &&
      intercomSettings &&
      !intercomSettings.user_id
    ) {
      window.Intercom('shutdown');
      window.Intercom('boot', intercomSettings);
      return;
    }
    if (
      intercomSettings &&
      prevIntercomSettings &&
      intercomSettings.user_id !== prevIntercomSettings.user_id
    ) {
      window.Intercom('update', intercomSettings);
    }
  }, [disableIntercom, intercomSettings, prevIntercomSettings]);
}

export interface IntercomProps {
  hideIntercomLauncher?: boolean;
}

export const Intercom: FC<IntercomProps> = props => {
  const { hideIntercomLauncher } = props;

  useIntercom(hideIntercomLauncher);

  return <div />;
};
