import * as React from 'react';
import {
  Box,
  Grid,
  GridCell,
  GridCellProps,
  Text,
  Hide
} from '@urbaninfrastructure/react-ui-kit';
import { useIntl, FormattedMessage } from 'react-intl';
import CSS from 'csstype';
import {
  ConnectionPriceMatrix_priceMatrix_matrix,
  ConnectionPriceMatrix,
  PriceMatrixItemType
} from '../../core-types';
import { categoryNames, messages } from './messages';
import { FormattedCurrency } from '../FormattedCurrency';
import { MatrixPeriodDuration } from './MatrixPeriodDuration';
import { MatrixPeriodPrice } from './MatrixPeriodPrice';
import {
  system,
  boxShadow,
  border,
  compose,
  layout,
  BorderProps,
  BoxShadowProps,
  LayoutProps,
  ResponsiveValue,
  ThemeValue,
  RequiredTheme,
  SpaceProps,
  space,
  TextAlignProps,
  textAlign,
  ColorProps,
  color
} from 'styled-system';
import styled from 'styled-components';

type Props = {
  variant?: 'simple';
  items: ConnectionPriceMatrix[];
  gridWidth?: GridCellProps['width'];
};

type MatrixRow = Omit<ConnectionPriceMatrix_priceMatrix_matrix, '__typename'>;

interface TableProps
  extends BorderProps,
    BoxShadowProps,
    LayoutProps,
    ColorProps,
    SpaceProps {
  borderSpacing?: ResponsiveValue<
    ThemeValue<'space', RequiredTheme>,
    RequiredTheme
  >;
  borderCollapse?: CSS.BorderCollapseProperty;
  tableLayout?: CSS.TableLayoutProperty;
}

const Table = styled.table<TableProps>`
  ${compose(
    space,
    color,
    border,
    boxShadow,
    layout,
    system({
      borderCollapse: {
        property: 'borderCollapse'
      },
      borderSpacing: {
        property: 'borderSpacing',
        scale: 'space'
      },
      tableLayout: {
        property: 'tableLayout'
      }
    })
  )};
`;

const Td = styled.td<LayoutProps & SpaceProps & BorderProps>`
  ${compose(layout, space, border)}
`;

const Th = styled.th<LayoutProps & SpaceProps & BorderProps & TextAlignProps>`
  ${compose(layout, space, border, textAlign)}
`;

export const ConnectionPriceMatrixes: React.FC<Props> = ({
  variant,
  items,
  gridWidth = { _: 1 }
}) => {
  const intl = useIntl();

  const freeUnlock: MatrixRow = {
    minutes: 0,
    price: 0,
    quantity: 1,
    type: PriceMatrixItemType.unlock
  };

  const parsed = items
    .map(item => ({
      ...item,
      name: categoryNames[item.vehicleCategory]
        ? intl.formatMessage(categoryNames[item.vehicleCategory])
        : item.vehicleCategory
    }))
    .sort((a, b) => a.name.localeCompare(b.name));

  const itemsLength = items.length;

  return (
    <Grid justifyContent="center" alignItems="baseline">
      {parsed.map(item => {
        const { lastStepInfinite, matrix } = item.priceMatrix;
        const unlock: MatrixRow =
          matrix.find(({ type }) => type === PriceMatrixItemType.unlock) ||
          freeUnlock;
        const periods = matrix.filter(
          ({ type }) => type === PriceMatrixItemType.period
        );
        const lastIndex = periods.length - 1;
        let endOfPeriod = 0;

        const borderSpacing = { _: 2, md: 4 };

        const tdProps = {
          width: 1 / 2,
          borderWidth: 0,
          px: 0,
          py: 0
        };

        const captionProps =
          variant === 'simple'
            ? {}
            : {
                color: 'primaryContrast',
                py: { _: 1, md: 2 },
                px: borderSpacing
              };

        const tableProps =
          variant === 'simple'
            ? { margin: '-.75rem', borderSpacing: '.75rem' }
            : {
                borderRadius: 'md',
                boxShadow: 'heavy',
                bg: 'white',
                borderSpacing
              };

        return (
          <GridCell key={item.id} width={gridWidth} mt="xxs">
            <Table
              {...tableProps}
              width={1}
              borderCollapse="separate"
              tableLayout="fixed"
            >
              <Box as="caption" {...captionProps} textAlign="left">
                <Hide
                  all={itemsLength === 1 ? true : false}
                  srOnly={itemsLength === 1 ? true : false}
                >
                  <Text as="span" typoStyle="xxs">
                    {item.name}
                  </Text>
                </Hide>
              </Box>

              <tbody>
                {unlock && (
                  <tr>
                    <Th {...tdProps} textAlign="left" scope="row">
                      <Text typoStyle="xxs">
                        <FormattedMessage {...messages.unlock} />
                      </Text>
                    </Th>

                    <Td {...tdProps}>
                      <Text typoStyle="xxs">
                        {unlock.price === 0 ? (
                          <FormattedMessage {...messages.included} />
                        ) : (
                          <FormattedCurrency value={unlock.price} />
                        )}
                      </Text>
                    </Td>
                  </tr>
                )}

                {periods.map((period, i) => {
                  const lastInfiniteStep = i === lastIndex && lastStepInfinite;

                  const startOfPeriod = endOfPeriod;
                  if (lastInfiniteStep) {
                    endOfPeriod = endOfPeriod + period.minutes;
                  } else {
                    endOfPeriod =
                      endOfPeriod + period.minutes * period.quantity;
                  }

                  return (
                    <tr key={i}>
                      <Th {...tdProps} textAlign="left" scope="row">
                        <Text bold typoStyle="xxs" whiteSpace="pre-wrap">
                          <MatrixPeriodDuration
                            end={endOfPeriod}
                            first={i === 0}
                            infinite={i === lastIndex && lastStepInfinite}
                            start={startOfPeriod}
                          />
                        </Text>
                      </Th>

                      <Td {...tdProps}>
                        <Text
                          typoStyle="xxs"
                          style={{
                            overflowWrap: 'break-word'
                          }}
                        >
                          <MatrixPeriodPrice period={period} />
                        </Text>
                      </Td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </GridCell>
        );
      })}
    </Grid>
  );
};
